@font-face {
  font-family: "Oswald";
  src: local("Oswald"), url("./fonts/Oswald-Bold.ttf") format("truetype");
}


h1 {
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
  color: #84bd00;
}

body {
  font-family: Arial;
}
.trenner {
  padding: 0 0.7rem 0 5rem;
}

.trenner__table {
  padding: 0 5rem 0 5rem;
}

.trenner__index {
  padding: 0 0 0 5rem;
}

.home {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: #84bd00;
  color: #fff;
}

.start-logo {
  padding-top: 20%;
}

.spinner {
  margin-top: 45vh;
}

.header__logo {
  margin-top: 2vh;
}

.header__hr {
  border-top: 4px solid rgba(0, 0, 0, 0.1);
}

#footer {
  margin-top: 2vh;
}

table {
  font-weight: bold;
}
